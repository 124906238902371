const _temp0 = require("./env.png");

const _temp1 = require("./env_.hdr");

module.exports = {
  "env":   {
    "png": _temp0
  },
  "env_":   {
    "hdr": _temp1
  }
}